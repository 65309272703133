import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(VCard, { attrs: { color: "dark" } }, [
        _c(
          "div",
          { class: _vm.isMobile() ? "py-10 px-5" : "pa-10" },
          [
            _c(
              VRow,
              { attrs: { justify: "center", align: "center" } },
              [
                _c(
                  VCol,
                  {
                    staticClass: "text-center",
                    staticStyle: { padding: "0" },
                    attrs: { cols: "12" },
                  },
                  [
                    _c("span", { staticStyle: { color: "var(--white)" } }, [
                      _c("strong", [_vm._v(_vm._s(_vm.Header))]),
                    ]),
                  ]
                ),
                _c(
                  VCol,
                  {
                    staticClass: "text-center",
                    staticStyle: { padding: "0", "margin-bottom": "16px" },
                    attrs: { cols: "12" },
                  },
                  [
                    _c("span", { staticStyle: { color: "var(--white)" } }, [
                      _c("strong", [_vm._v(_vm._s(_vm.Subtitle))]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: { "max-height": "100%", "overflow-y": "auto" },
                style: "height: 47vh",
                attrs: { "data-test": "pdfModalTerms:" + _vm.type },
                on: { scroll: _vm.scrollListener },
              },
              [
                _c("div", [
                  _c("p", {
                    staticStyle: { color: "var(--white)" },
                    domProps: { innerHTML: _vm._s(_vm.Html) },
                  }),
                ]),
              ]
            ),
            _c(
              "div",
              [
                _c(
                  VRow,
                  {
                    staticClass: "pt-6",
                    attrs: { justify: "center", align: "center" },
                  },
                  [
                    _c(
                      VCol,
                      {
                        staticClass: "text-center",
                        staticStyle: { padding: "0" },
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "acceptModal",
                            attrs: {
                              disabled: _vm.disabled,
                              "data-test": "pdfModalTerms:agree:" + _vm.type,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("agree")
                              },
                            },
                          },
                          [
                            _c("p", { staticClass: "acceptModal-title ma-2" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("read_agreed_txt")) + " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      VCol,
                      {
                        staticClass: "text-center",
                        staticStyle: { padding: "0" },
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "declineModal mt-2",
                            attrs: { text: "", height: "25" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("decline")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("cancel")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }